import React, { useState, useEffect } from "react";

// lib
import { Link, useNavigate } from "react-router-dom";

// custom
import Aside from "../../components/Client/Aside";
import { useAuthContext } from "../../contexts/auth_context";

function DeleteAccount() {
	const [status, setstatus] = useState(false);
	const navigate = useNavigate();
	const { deleteAccount, logout, user } = useAuthContext();

	const handleDelete = () => {
		setstatus(false);
		deleteAccount({ setstatus });
	};

	const handleCancel = () => {};

	useEffect(() => {
		if (status) {
			navigate("/delete");
		}
	}, [status]);

	return (
		<div className="my-10 h-auto  relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="sm:col-span-9 col-span-12">
						<div className="flex flex-col items-center space-y-6">
							<h3 className="S-xl:text-7xl text-6xl font-bold  text-clr-secondary-bright text-center">
								Delete Account
							</h3>
							<p className="text-clr-off-white text-3xl pt-10 mx-auto text-center">
								Are you sure you want to proceed? You will not be able to
								recover your account or data.
							</p>
							<div className="flex flex-row items-center justify-center space-x-5 pt-10">
								<button
									onClick={handleDelete}
									className="py-1 px-5 font-semibold bg-transparent border border-clr-main-bright rounded-lg text-clr-main-bright hover:text-clr-background hover:bg-clr-main-bright"
								>
									Yes
								</button>
								<button
									onClick={logout}
									className="py-1 px-5 font-semibold bg-transparent border border-red-600 rounded-lg text-red-600 hover:text-clr-background hover:bg-red-600"
								>
									NO
								</button>
							</div>
							<p className="text-center w-[90%] py-10 text-xl text-clr-main-bright">
								If you delete your account and do not take any action within 30
								days from deletion, or login to your account within the period
								of these 30 days, your account will be deleted automatically.
								Data including user information, email, password and other
								relevant account information and your activity will not be
								recoverable.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteAccount;
